import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m3891 2094 c-265 -32 -446 -112 -570 -251 -63 -71 -99 -133 -137 -238
-28 -77 -29 -87 -28 -250 0 -153 3 -181 27 -274 126 -472 441 -956 972 -1487
110 -110 227 -223 260 -251 33 -28 101 -86 150 -128 251 -215 634 -481 945
-657 471 -267 885 -434 1320 -532 255 -58 584 -63 773 -11 91 24 111 32 167
60 189 95 302 227 361 420 17 55 23 28 9 -40 -34 -162 -171 -334 -328 -413
-175 -87 -338 -117 -600 -109 -328 10 -615 74 -1037 230 -229 85 -604 271
-835 414 -36 22 -90 55 -120 74 -116 72 -282 187 -448 311 l-173 130 -57 -25
c-75 -33 -171 -63 -252 -78 -92 -17 -299 -14 -392 5 -192 41 -378 140 -490
262 -24 26 -48 52 -53 58 -22 22 -73 102 -99 154 -147 290 -139 680 19 956 25
43 45 82 45 87 0 5 -19 46 -43 91 -142 276 -210 515 -211 748 -1 160 16 242
75 368 115 241 404 391 754 390 l110 0 -114 -14z m409 -120 c388 -55 891 -237
1325 -479 61 -34 130 -73 155 -87 124 -69 290 -178 271 -178 -6 0 -32 13 -58
29 -137 84 -446 240 -624 316 -162 70 -423 156 -569 189 -316 70 -579 70 -760
-1 -298 -117 -389 -398 -258 -798 43 -134 155 -352 264 -515 53 -80 99 -147
103 -150 4 -3 28 -33 54 -68 208 -281 640 -698 995 -963 54 -40 113 -84 132
-99 53 -40 295 -201 380 -252 370 -223 722 -387 1035 -482 376 -115 678 -140
915 -75 202 55 326 180 365 367 8 35 16 67 19 70 3 3 6 -35 6 -84 -1 -187 -50
-315 -165 -430 -86 -86 -174 -134 -314 -172 -87 -23 -114 -25 -291 -26 -212 0
-301 11 -525 66 -108 26 -339 96 -390 117 -11 5 -38 15 -60 24 -189 72 -505
219 -653 304 -239 136 -433 259 -636 401 -63 43 -319 241 -363 279 -17 16 -50
43 -72 62 -125 102 -316 283 -476 452 -237 250 -445 520 -580 755 -185 320
-267 566 -268 804 -2 204 49 337 172 452 180 170 473 228 871 172z m1745
-1100 c104 -26 176 -56 249 -105 108 -71 168 -137 212 -232 26 -58 59 -211 49
-235 -3 -10 -53 -12 -226 -10 l-221 3 -13 38 c-26 76 -100 124 -208 134 -103
9 -230 -34 -272 -94 -25 -36 -36 -97 -23 -134 26 -79 102 -111 317 -135 450
-51 623 -150 686 -391 19 -74 19 -232 0 -308 -63 -239 -299 -405 -638 -446
l-89 -11 -81 48 c-241 140 -659 442 -676 488 -16 41 -14 73 5 80 22 9 427 7
440 -2 6 -4 14 -19 17 -33 11 -42 44 -76 105 -105 52 -25 67 -28 177 -28 129
-1 177 11 242 60 58 44 66 128 19 179 -41 45 -125 66 -311 80 -459 35 -676
222 -675 583 1 201 85 357 256 471 85 57 228 108 339 121 90 11 243 3 320 -16z
m-1810 6 c317 -46 548 -199 673 -446 37 -74 74 -197 70 -232 l-3 -27 -210 -3
c-115 -1 -219 0 -230 2 -12 3 -31 29 -50 66 -16 34 -49 79 -73 102 -45 42
-156 98 -192 98 -40 0 -59 21 -137 148 -97 157 -147 256 -140 276 9 24 174 33
292 16z m3423 -40 c272 -76 433 -240 460 -469 6 -52 5 -74 -4 -81 -7 -7 -90
-9 -214 -8 -221 3 -206 -1 -231 67 -29 77 -156 123 -279 101 -115 -21 -187
-75 -197 -148 -8 -56 1 -84 38 -120 39 -38 108 -57 279 -77 237 -28 361 -60
461 -121 141 -85 209 -220 209 -412 0 -213 -111 -383 -320 -488 -168 -84 -428
-110 -636 -64 -169 38 -328 140 -414 265 -38 55 -77 174 -82 245 l-3 55 218 3
218 2 15 -37 c21 -51 40 -71 94 -98 95 -48 252 -52 360 -9 48 19 57 26 83 64
30 44 19 108 -28 156 -26 26 -111 46 -274 65 -214 24 -282 40 -386 89 -125 60
-201 144 -246 271 -20 55 -23 83 -23 199 1 113 4 144 22 188 83 211 261 338
537 383 81 13 261 2 343 -21z"/>
<path d="M3645 5362 c-19 -142 75 -369 188 -455 56 -43 139 -77 213 -89 72
-11 157 -3 152 14 -2 6 -69 76 -149 157 -157 158 -252 262 -343 374 -21 26
-42 47 -46 47 -5 0 -11 -22 -15 -48z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
